import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IDiscardDialog } from "./types";
import { useStyles } from "./styles";
import { DialogContent, DialogContentText } from "@material-ui/core";

export const DiscardDialog = ({ open, validateAction }: IDiscardDialog) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => validateAction(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Save changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Changes will not be saved. Do you want to keep the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.spaceBetween}>
          <Button
            onClick={() => validateAction(false)}
            color="secondary"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={() => validateAction(true)}
            color="inherit"
            variant="contained"
            className={classes.cancelButton}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
