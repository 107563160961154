import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFilterById } from "../interface/serviceTypes";
import { IUserDealData } from "../interface/userDealTypes";
import { deleteUserDealService, getUserDeals } from "../service/userDealService";

export const fetchUserDeal = createAsyncThunk(
    "userdeal/fetchUsers",
    async ({id, rowsPerPage, page }:IFilterById ) => {
      const response: IUserDealData= await getUserDeals(id, rowsPerPage, page);
      return response
    }
  );
  

  export const  deleteUserDeals = createAsyncThunk(
    "userdeal/delete",
    async (id: string | number, {rejectWithValue}) => {
      try {
        const response = await deleteUserDealService(id)
      return response
      } catch(e){
        return rejectWithValue(e)
      }
    }
  );
  