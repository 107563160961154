import { createSlice } from "@reduxjs/toolkit";
import { fecthBusinessCSVReports, fecthBusinessReports, fecthUsersCSVReports, fecthUsersReports } from "../Actions/reportsAction";
import { IReports } from "../interface/reportsType";

const initialState: IReports = {
  status: "idle",

  businessReportsData: [],
  businessReportsCSVData: [],
  businessCount: 0,
  businessError: "",
  businessLoading: false,
  
  usersReportsData: [],
  usersReportsCSVData: [],
  usersCount: 0,
  usersError: "",
  usersLoading: false,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Business Reports
    builder
      .addCase(fecthBusinessReports.pending, (state) => {
        state.status = "loading";
        state.businessLoading = true;
      })
      .addCase(fecthBusinessReports.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessReportsData = action.payload.data;
        state.businessCount = action.payload.count;
        state.businessLoading = false;
      });
    
    // Users Reports 
    builder
      .addCase(fecthUsersReports.pending, (state) => {
        state.status = "loading";
        state.usersLoading = true;
      })
      .addCase(fecthUsersReports.fulfilled, (state, action) => {
        state.status = "idle";
        state.usersReportsData = action.payload.data;
        state.usersCount = action.payload.count;
        state.usersLoading = false;
      });

    // Business Reports CSV
    builder
      .addCase(fecthBusinessCSVReports.pending, (state) => {
        state.status = "loading";
        state.businessLoading = true;
      })
      .addCase(fecthBusinessCSVReports.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessReportsCSVData = action.payload;
        state.businessLoading = false;
      });

    // Users Reports CSV
    builder
      .addCase(fecthUsersCSVReports.pending, (state) => {
        state.status = "loading";
        state.usersLoading = true;
      })
      .addCase(fecthUsersCSVReports.fulfilled, (state, action) => {
        state.status = "idle";
        state.usersReportsCSVData = action.payload;
        state.usersLoading = false;
      });
  },
});

export default reportsSlice.reducer;
