import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportsParams, IReportsResponse } from "../interface/reportsType";
import { getBusinessCSVReports, getBusinessReports, getUsersCSVReports, getUsersReports } from "../service/reportsService";
// Reports
export const fecthBusinessReports = createAsyncThunk("businessReport/fetch", async (data: IReportsParams, { rejectWithValue }) => {
    try {
      const response = await getBusinessReports(data.page, data.rowsPerPage);
      let payload:IReportsResponse = {
        data: response.data.value.data,
        count: response.data.value.total,
      }
      return payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fecthUsersReports = createAsyncThunk("usersReport/fetch", async (data: IReportsParams, { rejectWithValue }) => {
    try {
      const response = await getUsersReports(data.page, data.rowsPerPage);
      let payload: IReportsResponse = {
        data: response.data.value.data,
        count: response.data.value.total,
      }
      return payload;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// CSV
export const fecthBusinessCSVReports = createAsyncThunk("businessReport/csv/fetch", async () => {
  try {
    const response = await getBusinessCSVReports();
    return response.data.value;
  } catch (err) {
    console.error(err);
    return [];
  }
}
);

export const fecthUsersCSVReports = createAsyncThunk("usersReport/csv/fetch", async () => {
  try {
    const response = await getUsersCSVReports();
    return response.data.value;
  } catch (err) {
    console.error(err);
    return [];
  }
}
);