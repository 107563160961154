import axiosInstance from "../Axios/axiosInstance";

// Reports
export const getBusinessReports = async (pageNumber: number, pageSize: number) => {
  const data = await axiosInstance.get(`/api/user-deal/report?PageNumber=${pageNumber}&PageSize=${pageSize}`);
  return data;
};

export const getUsersReports= async (pageNumber: number, pageSize: number) => {
  const data = await axiosInstance.get(`/api/admin-report?PageNumber=${pageNumber}&PageSize=${pageSize}`);
  return data;
};

// CSV
export const getBusinessCSVReports = async () => {
  const data = await axiosInstance.get(`/api/user-deal/report/export`);
  return data;
};

export const getUsersCSVReports= async () => {
  const data = await axiosInstance.get(`/api/admin-report/export`);
  return data;
};