import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { ILocationBusiness } from "../../interface/businessTypes";
import { useStyles } from "./styles";

export const ModalBusiness = ({
  open,
  setOpen,
  getValues,
}: ILocationBusiness) => {
  const classes = useStyles();
  const address = getValues("AddressModels[0].addressLine");
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Duplicate Location</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The following location is already being used by another business, if
            you proceed, this will cause an overlap between the businesses. To
            avoid this issue, please add to the Address input the
            number of the business, for example: {address} #(number).
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.okButtonContainer}>
          <Button
            onClick={() => setOpen(false)}
            color="secondary"
            variant="contained"
            className={classes.okButton}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
