import axiosInstance from "../Axios/axiosInstance";
import { IFormImageData } from "../Components/FormImage/types";

export const postPhotoService = async (dataPhoto: IFormImageData) => {
    try {
        console.log("Call endpoit using axiosInstance");
        const data = await axiosInstance.post("api/storage-item/admin/upload", dataPhoto);
        console.log("Response of endpoint: ", data);
        return data.data
    } catch (error){
        console.log("Error catch: ");
        console.log(error);
    }
};