import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { useStyles } from "./styles";
import { defaultColor } from "../../Styles/defaultStyles";
import { Sidebar } from "../../Components/Sidebar";
import { IReportsResponse } from "../../interface/reportsType";
import { CSVLink } from "react-csv";
import { BiDownload } from "react-icons/bi";
import {
  fecthUsersReports,
  fecthBusinessReports,
  fecthUsersCSVReports,
  fecthBusinessCSVReports,
} from "../../Actions/reportsAction";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip,
  Button,
} from "@material-ui/core";
import { EventNote } from "@material-ui/icons";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const Reports = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<number>(0);

  // User
  const [userData, setUserData] = useState<Array<any>>([]);
  const [userCSVData, setUserCSVData] = useState<Array<Array<any>>>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [userPage, setUserPage] = useState<number>(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState<number>(10);

  // Business
  const [businessData, setBusinessData] = useState<Array<any>>([]);
  const [businessCSVData, setBusinessCSVData] = useState<Array<Array<any>>>([]);
  const [businessCount, setBusinessCount] = useState<number>(0);
  const [businessPage, setBusinessPage] = useState<number>(0);
  const [businessRowsPerPage, setBusinessRowsPerPage] = useState<number>(10);

  const handleChange = (event: any, newValue: number) => {
    if (newValue === 0) {
      setBusinessPage(0);
      setBusinessRowsPerPage(10);
    } else if (newValue === 1) {
      setUserPage(0);
      setUserRowsPerPage(10);
    }
    setValue(newValue);
  };

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }
  
  const formatDate = (date: string) => {
    const dateTime = new Date(date);
    return [
      padTo2Digits(dateTime.getMonth() + 1),
      padTo2Digits(dateTime.getDate()),
      dateTime.getFullYear(),
    ].join('-');
  }

  const getTitle = (name: string) => (
    <Typography variant="subtitle1" className={classes.title}>
      {name}
    </Typography>
  );

  const handleChangeUserPage = (event: any, newPage: number): void => {
    setUserPage(newPage);
  };

  const handleChangeRowsPerUserPage = (event: any): void => {
    setUserRowsPerPage(parseInt(event.target.value, 10));
    setUserPage(0);
  };

  const handleChangeBusinessPage = (event: any, newPage: number): void => {
    setBusinessPage(newPage);
  };

  const handleChangeRowsPerBusinessPage = (event: any): void => {
    setBusinessRowsPerPage(parseInt(event.target.value, 10));
    setBusinessPage(0);
  };

  useEffect(() => {
    let cancel = false;
    const loadData = async () => {
      // Table Users
      const dataUser = {
        rowsPerPage: userRowsPerPage,
        page: userPage + 1,
      };
      await dispatch(fecthUsersReports(dataUser)).then(async (res) => {
        if (cancel) return;
        let response = res.payload as IReportsResponse;
        setUserData(response.data);
        setUserCount(response.count);
      });

      // Table Business
      const dataBussiness = {
        rowsPerPage: businessRowsPerPage,
        page: businessPage + 1,
      };
      await dispatch(fecthBusinessReports(dataBussiness)).then(async (res) => {
        if (cancel) return;
        let response = res.payload as IReportsResponse;
        setBusinessData(response.data);
        setBusinessCount(response.count);
      });

      // CSV Users
      await dispatch(fecthUsersCSVReports()).then(async (res) => {
        if (cancel) return;
        setUserCSVData(res.payload);
      });

      // CSV Business
      await dispatch(fecthBusinessCSVReports()).then(async (res) => {
        if (cancel) return;
        setBusinessCSVData(res.payload);
      });
    };

    loadData();
    return () => {
      cancel = true;
    };
  }, [businessPage, businessRowsPerPage, dispatch, userPage, userRowsPerPage]);

  return (
    <Sidebar>
      <Grid className={classes.container}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="By User" {...a11yProps(0)} />
              <Tab label="By Business" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{getTitle("User")}</TableCell>
                    <TableCell align="left">{getTitle("Email")}</TableCell>
                    <TableCell align="center">
                      {getTitle("Offers Redeemed")}
                    </TableCell>
                    <TableCell align="center">
                      {getTitle("Sign Up Date")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.map((user, index) => (
                    <TableRow key={`User-${index}`}>
                      <TableCell align="left">{user.user}</TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="center">{user.offers_redeemed}</TableCell>
                      <TableCell align="center">
                        <Chip
                          avatar={<EventNote />}
                          label={formatDate(user.sign_up_date)}
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                className={classes.footer}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={userCount}
                rowsPerPage={userRowsPerPage}
                page={userPage}
                onPageChange={handleChangeUserPage}
                onRowsPerPageChange={handleChangeRowsPerUserPage}
              />
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {getTitle("Business Name")}
                    </TableCell>
                    <TableCell align="left">{getTitle("Offer Used")}</TableCell>
                    <TableCell align="left">{getTitle("User")}</TableCell>
                    <TableCell align="left">{getTitle("Email")}</TableCell>
                    <TableCell align="center">{getTitle("Date")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessData.map((business, index) => (
                    <TableRow key={`Business-${index}`}>
                      <TableCell align="left">
                        {business.business_name}
                      </TableCell>
                      <TableCell align="left">{business.offer_used}</TableCell>
                      <TableCell align="left">{business.user}</TableCell>
                      <TableCell align="left">{business.email}</TableCell>
                      <TableCell align="center">
                        <Chip
                          avatar={<EventNote />}
                          label={formatDate(business.date)}
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                className={classes.footer}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={businessCount}
                rowsPerPage={businessRowsPerPage}
                page={businessPage}
                onPageChange={handleChangeBusinessPage}
                onRowsPerPageChange={handleChangeRowsPerBusinessPage}
              />
            </TableContainer>
          </TabPanel>
        </Box>
        <Grid className={classes.container}>
          <CSVLink 
            color={defaultColor.white} 
            separator=";" 
            filename={value === 0 ? "by-users.csv": value === 1 ? "by-business.csv" : "file.csv"}
            data={value === 0 ? userCSVData : value === 1 ? businessCSVData : [] as any}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<BiDownload />}
            >
              Export list to CSV file
            </Button>
          </CSVLink>
        </Grid>
      </Grid>
    </Sidebar>
  );
};
