import {
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { IoReturnDownBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Sidebar } from "../../../Components/Sidebar";
import { IFormPut, ILocation } from "../../../interface/businessTypes";
import { clearEditBusiness } from "../../../Reducer/businessReducer";
import { path } from "../../../Routes/path";
import { useQuery } from "../../../utils/getQuery";
import { useStyles } from "../styles";
import { FormContact } from "./formContact";
import { FormDeals } from "./formDeals";
import { FormDetails } from "./formDetails";
import { Notification } from "../../../Components/Notification";
import { useEffect, useState } from "react";
import { Location } from "../../../Components/Location";
import { addBusinessSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createBusiness,
  getEditBusiness,
  updateBusiness,
} from "../../../Actions/businessAction";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { FormImage } from "../../../Components/FormImage";
import { IImage } from "../../../interface/imageType";
import { DiscardDialog } from "../../../Components/DiscardDialog";
import { stringDateFormat } from "../../../utils/dateFormat";
import { ModalBusiness } from "../modal";
import { exitsModel } from "../../../utils/exitsModel";

export const FormBusinesses = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const id = useQuery(search, "id");
  const selectedData = useAppSelector((state: RootState) => state.business);
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [urlPhoto, setUrlPhoto] = useState<string>("");
  const [openDiscard, setOpenDiscard] = useState<boolean>(false);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [urlPhotoDeal1, setUrlPhotoDeal1] = useState<string>("");
  const [urlPhotoDeal2, setUrlPhotoDeal2] = useState<string>("");
  const [openDeal1, setOpenDeal1] = useState<boolean>(false);
  const [openDeal2, setOpenDeal2] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: { ...(selectedData.edit as IFormPut) },
    resolver: yupResolver(addBusinessSchema),
  });

  useEffect(() => {
    if (location.pathname === path.BUSINESSESEDIT && !id) {
      return navigate(path.BUSINESSES);
    }
    if (id) {
      dispatch(getEditBusiness(id)).then((res) => {
        let data: any = res.payload;
        if (data.dealModels[0]?.storage_image !== undefined) {
          setUrlPhotoDeal1(data.dealModels[0].storage_image);
        }
        if (data.dealModels[1]?.storage_image !== undefined) {
          setUrlPhotoDeal2(data.dealModels[1].storage_image);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedData.edit) return;
    reset(selectedData.edit);
  }, [selectedData.edit]);

  // ***This logic is no longer used but may be used in future updates or upgrades
  // useEffect(() => {
  //   if (!address) return;
  //   getDataValidationLocation();
  // }, [address]);

  const validateLocation = async (data: IFormPut) => {
    const location = data.AddressModels[0].location;
    if (!location?.latitude || !location?.longitude) {
      const result = await getLocation(location, data);
      data.AddressModels[0].location.latitude = result.latitude;
      data.AddressModels[0].location.longitude = result.longitude;
    }
    return;
  };

  const deleteImage = (images: IImage[]): number[] => {
    if (!images) return [];
    let result: number[] = [];
    images.forEach((img: IImage) => {
      if (img.itemHash !== urlPhoto && urlPhoto !== "") {
        result.push(img.id);
      }
    });
    return result;
  };

  const onSubmit = async (data: IFormPut) => {
    if (!data) return;
    data.imageFile = urlPhoto ?? "";
    data.ImageIdsToDelete = deleteImage(data.images);
    data.dealModels[0].storage_image = urlPhotoDeal1;
    data.dealModels[1].storage_image = urlPhotoDeal2;

    if (data.dealModels[0].storage_image === "") {
      Notification({
        title: "Error",
        message: `The Image for Deal 1 is Required`,
        type: "danger",
      });
      return;
    }

    if (
      (data.dealModels[1].title !== "" ||
        `${data.dealModels[1].maxMoneyAmount}` !== "" ||
        data.dealModels[1].shortDescription !== "" ||
        data.dealModels[1].disclaimer !== "") &&
      data.dealModels[1].storage_image === ""
    ) {
      Notification({
        title: "Error",
        message: `The Image for Deal 2 is Required`,
        type: "danger",
      });
      return;
    }

    await validateLocation(data);
    // ***This logic is no longer used but may be used in future updates or upgrades
    // const checkExistLocation = await checkLocation(
    //   { ...data.AddressModels[0].location },
    //   data.AddressModels[0].addressLine
    // );
    // if (checkExistLocation) return;
    if (location.pathname === path.BUSINESSESCREATE) {
      const responsePost: any = await dispatch(createBusiness(data));
      if (responsePost!.payload!.success) {
        Notification({
          title: "Success",
          message: "Business created",
          type: "success",
        });
        return navigate(path.BUSINESSES);
      } else {
        Notification({
          title: "Error",
          message: `Error to adding business: ${data.BusinessName}`,
          type: "danger",
        });
      }
    }
    if (location.pathname === path.BUSINESSESEDIT) {
      const responsePut: any = await dispatch(
        updateBusiness({
          data,
          edit: selectedData.edit?.AddressModels[0].workingHours,
        })
      );
      if (responsePut!.payload!.status === 200) {
        Notification({
          title: "Success",
          message: "Business edited",
          type: "success",
        });
        return navigate(path.BUSINESSES);
      } else {
        Notification({
          title: "Error",
          message: `Error to edit business: ${data.BusinessName}`,
          type: "danger",
        });
      }
    }
  };

  const backHandler = () => {
    dispatch(clearEditBusiness());
    navigate(path.BUSINESSES);
  };
  const currentImage = () => {
    if (urlPhoto !== "") return urlPhoto;
    if (
      selectedData.edit?.images?.[0] &&
      selectedData.edit?.images?.[0]?.itemHash
    ) {
      return selectedData.edit?.images?.[0]?.itemHash;
    }
    return "";
  };

  const checkChanges = async () => {
    if (location.pathname === path.BUSINESSESCREATE || !id) {
      return backHandler();
    }
    const oldData = selectedData.edit as IFormPut;
    const data: IFormPut = getValues();
    data.AddressModels[0].workingHours = stringDateFormat(
      data.AddressModels[0].workingHours
    );
    const newData = exitsModel(data);
    if (urlPhoto && urlPhoto !== selectedData.edit?.images?.[0]?.itemHash) {
      return setOpenDiscard(true);
    }
    if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
      return setOpenDiscard(true);
    }
    return backHandler();
  };

  const validateAction = async (value: boolean) => {
    setOpenDiscard(false);
    if (value) {
      const data: IFormPut = getValues();
      await onSubmit(data);
    }
    return backHandler();
  };

  // ***This logic is no longer used but may be used in future updates or upgrades
  // const getDataValidationLocation = async () => {
  //   const data: IFormPut = getValues();
  //   const location = data.AddressModels[0].location;
  //   let latitude = location ? location.latitude : null;
  //   let longitude = location ? location.longitude : null;
  //   if (!location?.latitude || !location?.longitude) {
  //     const result = await getLocation(location, data);
  //     latitude = result.latitude;
  //     longitude = result.longitude;
  //   }
  //   checkLocation({ latitude, longitude }, data.AddressModels[0].addressLine);
  // };
  // const checkLocation = async (
  //   { longitude, latitude }: ILocation,
  //   addressLine: string
  // ) => {
  //   if (addressLine === selectedData.edit?.AddressModels[0].addressLine)
  //     return false;
  //   const existLocation = await existLocationService({ longitude, latitude });
  //   if (existLocation) {
  //     setError("AddressModels[0].addressLine" as any, {
  //       type: "location",
  //       message: "This field needs extra information",
  //     });
  //     setOpenLocation(true);
  //     return true;
  //   }
  //   if (!existLocation) {
  //     clearErrors("AddressModels[0].addressLine" as any);
  //     return false;
  //   }
  // };

  const getLocation = async (
    location: ILocation,
    data: IFormPut
  ): Promise<ILocation> => {
    const address = data.AddressModels[0];
    const addressLocation = (address.addressLine +
      "," +
      address.city +
      "," +
      address.state +
      "," +
      address.zipCode) as string;
    const locationAddress = await geocodeByAddress(addressLocation);
    const latLong = await getLatLng(locationAddress[0]);
    return { latitude: latLong.lat, longitude: latLong.lng };
  };

  return (
    <Sidebar>
      <Grid container className={classes.formDiv}>
        <Paper variant="elevation" elevation={3} className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Business Details
          </Typography>
          <Grid container className={classes.form}>
            <Typography variant="subtitle1"> Quick address search: </Typography>
            <FormControl fullWidth>
              <Location
                address={address}
                setAddress={setAddress}
                setValue={setValue}
              />
            </FormControl>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBusiness
              open={openLocation}
              setOpen={setOpenLocation}
              getValues={getValues}
            />
            <FormDetails
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
            />
            <Typography variant="h5" className={classes.title}>
              Contact information
            </Typography>
            <FormContact
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
            />
            <Typography variant="h5" className={classes.title}>
              Deals
            </Typography>
            <FormDeals
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              urlPhotoDeal1={urlPhotoDeal1}
              urlPhotoDeal2={urlPhotoDeal2}
              setOpenDeal1={setOpenDeal1}
              setOpenDeal2={setOpenDeal2}
            />
            <Typography variant="h5" className={classes.title}>
              Assets
            </Typography>
            {currentImage() && (
              <Paper
                variant="elevation"
                elevation={3}
                className={classes.paperImage}
              >
                <img
                  src={currentImage()}
                  title="logo"
                  alt="Cover"
                  className={classes.imageContainer}
                />
              </Paper>
            )}
            <Grid className={classes.form}>
              <Typography variant="subtitle1"> Cover Photo: </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(true)}
              >
                Browse
              </Button>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.margiButton}
            >
              {location.pathname === path.BUSINESSESCREATE ? "Add" : "Edit"}{" "}
              Business
            </Button>
          </form>
          <FormImage
            open={open}
            setOpen={setOpen}
            service="businesses"
            setUrl={setUrlPhoto}
          />
          <FormImage
            open={openDeal1}
            setOpen={setOpenDeal1}
            service="businesses"
            setUrl={setUrlPhotoDeal1}
          />
          <FormImage
            open={openDeal2}
            setOpen={setOpenDeal2}
            service="businesses"
            setUrl={setUrlPhotoDeal2}
          />
          <Grid>
            <Button
              variant="text"
              className={classes.buttomBack}
              onClick={() => checkChanges()}
              color="secondary"
              startIcon={<IoReturnDownBack />}
            >
              Back to Business
            </Button>
          </Grid>
          <DiscardDialog open={openDiscard} validateAction={validateAction} />
        </Paper>
      </Grid>
    </Sidebar>
  );
};
